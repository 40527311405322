import React, { useEffect, useState } from "react";
import { Style } from "./style";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, Select, Space } from "antd";
import { RxCross2 } from "react-icons/rx";
import { getUsers } from "../../redux/reducers/users";
import { useTranslation } from "react-i18next";
import ProductCard from "../../components/productCard";
import Navbar from "../../components/navbar";
import Loader from "../../components/loader";

export default function Products() {
  const {
    products: { products },
    data: { categories },
    users: { user, sellers },
  } = useSelector((state) => state);
  const [search, setSearch] = useState({ name: "", category: "" });
  const dispatch = useDispatch();
  const [text] = useTranslation("global");

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  return (
    <Style>
      <Navbar />
      {products.length > 0 ? (
        <div className="container">
          <div className="header">
            <h1 className="title">{text("products.title")}</h1>
            <div className="inputs">
              <Space.Compact className="select">
                <Select
                  value={search.category !== "" ? search.category : null}
                  showSearch
                  options={categories.map((e) => ({
                    value: e.type,
                    label: e.type,
                  }))}
                  onChange={(e) => setSearch((p) => ({ ...p, category: e }))}
                  className="select"
                  placeholder={text("products.category")}
                />
                <Button
                  onClick={() => setSearch((p) => ({ ...p, category: "" }))}
                >
                  <RxCross2 />
                </Button>
              </Space.Compact>
              <Input
                placeholder={text("products.search")}
                value={search.name}
                onChange={(e) =>
                  setSearch((p) => ({ ...p, name: e.target.value }))
                }
              />
            </div>
          </div>
          <div className="cards">
            {products
              .filter((e) => e.status)
              .filter(
                (e) =>
                  sellers?.find((seller) => seller.id === e.author)?.country ===
                  user.country
              )
              .filter((e) =>
                e.category
                  ?.toLowerCase()
                  .includes(search.category.toLowerCase())
              )
              .filter((e) =>
                e.name?.toLowerCase().includes(search.name.toLowerCase())
              )
              .map((item, index) => (
                <ProductCard key={index} item={item} />
              ))}
          </div>
        </div>
      ) : (
        <Loader w="100%" h="100dvh" />
      )}
    </Style>
  );
}
