import React, { useEffect, useState } from "react";
import { Style } from "./style";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, Select, Space } from "antd";
import { RxCross2 } from "react-icons/rx";
import { setUserProducts } from "../../redux/reducers/products";
import { useTranslation } from "react-i18next";
import ProductCard from "../productCard";

export default function SellerProducts({ id }) {
  const {
    products: { products, userProducts },
    data: { categories },
  } = useSelector((state) => state);
  const [search, setSearch] = useState({
    name: "",
    category: "",
  });
  const dispatch = useDispatch();
  const [text] = useTranslation("global");

  useEffect(() => {
    dispatch(setUserProducts(id));
  }, [id, products]);

  return (
    <Style>
      <div className="header">
        <h1 className="title">{text("sellerProducts.products")}</h1>
        <div className="inputs">
          <Space.Compact className="select">
            <Select
              value={search.category !== "" ? search.category : null}
              showSearch
              options={categories.map((e) => ({
                value: e.type,
                label: e.type,
              }))}
              onChange={(e) => setSearch((p) => ({ ...p, category: e }))}
              className="select"
              placeholder={text("sellerProducts.category")}
            />
            <Button onClick={() => setSearch((p) => ({ ...p, category: "" }))}>
              <RxCross2 />
            </Button>
          </Space.Compact>
          <Input
            placeholder={text("sellerProducts.search")}
            value={search.name}
            onChange={(e) => setSearch((p) => ({ ...p, name: e.target.value }))}
          />
        </div>
      </div>
      <div className="cards">
        {userProducts
          .filter((e) => e.status)
          .filter((e) =>
            e.category?.toLowerCase().includes(search.category.toLowerCase())
          )
          .filter((e) =>
            e.name?.toLowerCase().includes(search.name.toLowerCase())
          )
          .map((item, index) => (
            <ProductCard key={index} item={item} />
          ))}
      </div>
    </Style>
  );
}
