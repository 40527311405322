import React, { useEffect } from "react";
import { Block } from "./style";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function Ban() {
  const { user } = useSelector((state) => state.users);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user.ban) navigate("/");
  }, [user]);

  return (
    <Block>
      <h1>Ваш аккаунт заблокирован!</h1>
    </Block>
  );
}
