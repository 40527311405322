import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";

const cart = createSlice({
  name: "cart",
  initialState: {
    cartProducts: [],
  },
  reducers: {
    addCartProduct: (state, { payload }) => {
      state.cartProducts.push(payload);
    },
    removeCartProduct: (state, { payload }) => {
      state.cartProducts.map((item, index) => {
        if (item.id === payload.id) {
          state.cartProducts.splice(index, 1);
        }
      });
    },
    setCartProduct: (state, { payload }) => {
      state.cartProducts.map((item, index) => {
        if (item.id === payload.id) {
          state.cartProducts.splice(index, 1, payload);
        }
      });
    },
    clearCart: (state) => {
      state.cartProducts = [];
    },
    error: (_, { payload }) => {
      message.error(payload);
    },
  },
});

export const { addCartProduct, removeCartProduct, setCartProduct, clearCart } =
  cart.actions;
export default cart.reducer;
