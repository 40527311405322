import styled from "styled-components";

export const Block = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  button {
    height: 20px;
    width: 20px;
    color: #fff;
    border-radius: 50%;
    background-color: #000000;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    &:hover {
      background-color: #12131d !important;
    }
    &:disabled {
      background-color: #52525270;
      color: #fff;
    }
  }
`;
