import React, { useEffect, useState } from "react";
import {
  Button,
  Drawer,
  Form,
  Input,
  InputNumber,
  Select,
  message,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { FormStyle } from "../styles/content";
import MultiUploader from "../components/multiUploader";
import { createProduct, toggleLoader } from "../redux/reducers/products";
import { useTranslation } from "react-i18next";
import countries from "../data/countries.json";

export default function ProductCreator({ open, setOpen }) {
  const [text] = useTranslation("global");
  const dispatch = useDispatch();
  const {
    users: { user },
    products: { creatorLoader },
    data: { categories },
  } = useSelector((state) => state);
  const { TextArea } = Input;
  const [images, setImages] = useState([]);
  const [current, setCurrent] = useState("$");
  const [value, setValue] = useState({ amount: 1 });
  const currency = countries.find((e) => e.country === user.country)?.currency;

  useEffect(() => {
    if (!creatorLoader) {
      setOpen(false);
      setValue({ amount: 1 });
      setImages([]);
    }
  }, [creatorLoader]);

  const onFinish = (data) => {
    if (images.length > 0) {
      dispatch(toggleLoader("creatorLoader"));
      dispatch(createProduct({ ...data, images, current, author: user.id }));
    } else {
      message.warning(text("productCreator.uploadPhoto"));
    }
  };

  const onClose = () => setOpen(false);

  const selectAfter = (
    <Select value={current} onChange={(value) => setCurrent(value)}>
      <Select.Option value="$">$</Select.Option>
      <Select.Option value={currency}>{currency}</Select.Option>
    </Select>
  );

  return (
    <Drawer title={text("productCreator.title")} onClose={onClose} open={open}>
      <MultiUploader images={images} setImages={setImages} />
      <FormStyle>
        <Form onFinish={onFinish} layout="vertical" initialValues={value}>
          <Form.Item
            name={"name"}
            label={text("productCreator.name")}
            rules={[
              {
                required: true,
                message: text("productCreator.namePlaceholder"),
              },
            ]}
          >
            <Input className="input" />
          </Form.Item>
          <Form.Item
            name={"category"}
            label={text("productCreator.category")}
            rules={[
              {
                required: true,
                message: text("productCreator.categoryPlaceholder"),
              },
            ]}
          >
            <Select
              showSearch
              options={categories?.map((e) => ({
                value: e.type,
                label: e.type,
              }))}
              className="input"
            />
          </Form.Item>
          <Form.Item
            name={"price"}
            label={text("productCreator.price")}
            rules={[
              {
                required: true,
                message: text("productCreator.pricePlaceholder"),
              },
            ]}
          >
            <InputNumber
              addonAfter={selectAfter}
              className="number"
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            name={"amount"}
            label={text("productCreator.amount")}
            rules={[
              {
                required: true,
                message: text("productCreator.amountPlaceholder"),
              },
            ]}
          >
            <InputNumber
              className="number"
              defaultValue={1}
              min={0}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            name={"description"}
            label={text("productCreator.description")}
            rules={[
              {
                required: true,
                message: text("productCreator.descriptionPlaceholder"),
              },
            ]}
          >
            <TextArea rows={4} className="textarea" />
          </Form.Item>
          <Form.Item>
            <Button
              htmlType="submit"
              loading={creatorLoader}
              className="text-black mt-5 btn"
            >
              {text("productCreator.submit")}
            </Button>
          </Form.Item>
        </Form>
      </FormStyle>
    </Drawer>
  );
}
