import { useEffect, useState } from "react";
import { FaCloudUploadAlt } from "react-icons/fa";
import { Button, Image } from "antd";
import { Block } from "./style";
import { baseUrl, headers } from "../../utils";
import { RiLoader2Line } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import axios from "axios";

export default function MultiUploader({ images, setImages }) {
  const [disable, setDisable] = useState(false);
  const [loader, setLoader] = useState(false);
  const [text] = useTranslation("global");

  useEffect(() => {
    if (images.length == 3) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [images.length]);

  function upload(e) {
    setLoader(true);
    if (!disable && e !== undefined) {
      const data = new FormData();
      data.append("image", e);
      axios.post(baseUrl("/images"), data, headers).then((res) => {
        setImages((p) => [...p, res.data.id]);
        setLoader(false);
      });
    }
  }

  function remove(id) {
    setImages(images.filter((item) => item !== id));
  }

  return (
    <Block>
      <input
        type="file"
        id="image"
        disabled={disable}
        accept="image/*"
        onChange={(e) => upload(e.target.files[0])}
      />
      <label htmlFor="image">
        {loader ? <RiLoader2Line className="loader" /> : <FaCloudUploadAlt />}
        <p>{disable ? text("uploader.limit") : text("uploader.upload")}</p>
      </label>
      <div className="images">
        <Image.PreviewGroup>
          {images.length == 0 ? (
            <br />
          ) : (
            images.map((item, index) => (
              <div className="card" key={index}>
                <div className="image">
                  <Image src={baseUrl(`/images/${item}`)} />
                </div>
                <Button onClick={() => remove(item)} danger>
                  {text("uploader.remove")}
                </Button>
              </div>
            ))
          )}
        </Image.PreviewGroup>
      </div>
    </Block>
  );
}
