import React, { useEffect } from "react";
import { Drawer, Carousel, Image, Button, Empty, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { baseUrl } from "../utils";
import { Btn, Card } from "../styles/content";
import { createOrder, setLoader } from "../redux/reducers/orders";
import { useNavigate } from "react-router-dom";
import { clearCart } from "../redux/reducers/cart";
import { updateProductAction } from "../redux/reducers/products";
import { useTranslation } from "react-i18next";
import Counter from "../components/counter";

export default function Cart({ open, setOpen }) {
  const [text] = useTranslation("global");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    cart: { cartProducts },
    orders: { buyLoader },
    users: { user },
  } = useSelector((state) => state);

  useEffect(() => {
    if (buyLoader === "end") {
      dispatch(clearCart());
      setOpen(false);
      navigate("/");
      dispatch(setLoader({ loader: "buyLoader", value: false }));
      message.success(text("cart.newOrderMessage"));
    }
  }, [buyLoader]);

  function order() {
    dispatch(setLoader({ loader: "buyLoader", value: true }));
    dispatch(
      createOrder({
        buyer: localStorage.getItem("userId"),
        seller: cartProducts[0].author,
        ready: false,
        address: user.address,
        products: cartProducts,
      })
    );
    cartProducts.map((item) =>
      dispatch(
        updateProductAction({ ...item, amount: item.amount - item.count })
      )
    );
  }

  return (
    <Drawer
      title={text("cart.title")}
      onClose={() => setOpen(false)}
      open={open}
    >
      {cartProducts.length > 0 ? (
        <>
          {cartProducts.map((item, index) => (
            <Card className="card" key={index}>
              <Carousel arrows autoplay>
                {item.images.map((img, index) => (
                  <div className="image" key={index}>
                    <Image src={baseUrl(`/images/${img}`)} className="img" />
                  </div>
                ))}
              </Carousel>
              <div className="line">
                <div className="text">
                  <h3>{item.name}</h3>
                  <b>
                    {item.price} {item.current}
                  </b>
                  <p>Кол-во {item.amount}</p>
                </div>
                <Counter item={item} />
              </div>
            </Card>
          ))}
          <Btn>
            <Button onClick={order} loading={buyLoader} disabled={buyLoader}>
              {text("cart.orderButton")}
            </Button>
          </Btn>
        </>
      ) : (
        <Empty description={text("cart.empty")} />
      )}
    </Drawer>
  );
}
