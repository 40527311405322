import styled from "styled-components";

export const Block = styled.div`
  input {
    display: none;
  }
  .loader {
    animation: 1s loader infinite linear;
    @keyframes loader {
      100% {
        transform: rotate(360deg);
      }
    }
  }
  label {
    height: 50px;
    width: 100%;
    border-radius: 10px;
    border: 2px dashed grey;
    display: flex;
    font-size: 16px;
    align-items: center;
    gap: 15px;
    padding: 15px;
  }
  .images {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 10px;
    .card {
      display: flex;
      flex-direction: column;
      align-items: center;
      .image {
        width: 85px;
        height: 85px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px auto;
        overflow: hidden;
        border-radius: 10px;
        img {
          object-fit: cover;
          min-height: 85px;
          min-width: 85px;
        }
      }
    }
  }
`;
