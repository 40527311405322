import React, { useEffect } from "react";
import { Block } from "./style";
import { getOrders, updateOrder } from "../../redux/reducers/orders";
import { useDispatch, useSelector } from "react-redux";
import { SandB } from "../../utils";
import { addOrder, toggleLoader } from "../../redux/reducers/orders";
import { updateProductAction } from "../../redux/reducers/products";
import { pusher } from "../../pusher";
import Navbar from "../../components/navbar";
import OrderCard from "../../components/orderCard";
import Loader from "../../components/loader";
import { Divider } from "antd";
import { useTranslation } from "react-i18next";

export default function Home() {
  const dispatch = useDispatch();
  const { orders, dataLoader } = useSelector((state) => state.orders);
  const id = localStorage.getItem("userId");
  const [text] = useTranslation("global");

  useEffect(() => {
    dispatch(toggleLoader("dataLoader"));
    dispatch(getOrders());
  }, []);

  useEffect(() => {
    pusher.connection.bind("connected", function () {
      console.log("Successfully connected to Pusher!");
    });

    const channel = pusher.subscribe("orders");

    channel.bind("order-added", (data) => {
      data.products.map((item) =>
        dispatch(updateProductAction({ ...item, count: 1 }))
      );
      dispatch(addOrder(data));
    });

    channel.bind("ready-edited", (data) => {
      dispatch(updateOrder(data));
    });

    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, [pusher]);

  return (
    <Block>
      <Navbar />
      {dataLoader ? (
        orders.length > 0 ? (
          <div className="cards">
            <Divider>{text("orders.news")}</Divider>
            {orders
              .filter((e) => !e.ready)
              .filter(
                SandB(
                  (e) => e.seller === id,
                  (e) => e.buyer === id
                )
              )
              .map((item, index) => (
                <OrderCard item={item} key={index} />
              ))}
            <Divider>{text("orders.ready")}</Divider>
            {orders
              .filter((e) => e.ready)
              .filter(
                SandB(
                  (e) => e.seller === id,
                  (e) => e.buyer === id
                )
              )
              .map((item, index) => (
                <OrderCard item={item} key={index} />
              ))}
          </div>
        ) : (
          ""
        )
      ) : (
        <Loader w="100%" h="100dvh" />
      )}
    </Block>
  );
}
