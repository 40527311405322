import styled from "styled-components";

export const Block = styled.label`
  height: 150px;
  width: 150px;
  border-radius: 50%;
  margin: 20px auto;
  background: var(--contentBg);
  border: 2px dashed gold;
  color: var(--contentColor);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  input {
    display: none;
  }
  p {
    width: 80%;
    text-align: center;
  }
  .placeholder {
    width: 40%;
  }
  .img {
    max-width: 100%;
    max-height: 100%;
  }
`;
