import styled from "styled-components";

export const Style = styled.div`
  width: 300px;
  border-radius: 20px;
  padding: 20px;
  border: 1px solid #fff;
  background-color: #ffffffae;
  backdrop-filter: blur(20px);
  cursor: pointer;
  .head {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 15px;
    .image {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 1px solid #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      img {
        object-fit: cover;
        min-width: 50px;
        min-height: 50px;
      }
      b {
        font-size: 40px;
        user-select: none;
      }
    }
  }
  .content {
    margin-top: 10px;
    a {
      text-decoration: none;
      color: #44599c;
    }
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    padding: 10px;
    .image {
      height: 50px;
    }
  }
`;
