import React, { useState } from "react";
import { Style } from "./style";
import { Button, Carousel, Dropdown, Image, Popconfirm, Tooltip } from "antd";
import { SandB, baseUrl, formatNumber } from "../../utils";
import { HiDotsHorizontal } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { removeProduct, updateProduct } from "../../redux/reducers/products";
import { FaPencilAlt } from "react-icons/fa";
import { BiSolidTrash } from "react-icons/bi";
import { RiEyeCloseFill, RiEyeFill } from "react-icons/ri";
import { MdOutlineShoppingCart, MdRemoveShoppingCart } from "react-icons/md";
import { addCartProduct, removeCartProduct } from "../../redux/reducers/cart";
import { useTranslation } from "react-i18next";
import ProductEditor from "../../content/productEditor";

export default function ProductCard({ item }) {
  const dispatch = useDispatch();
  const [editModal, setEditModal] = useState(false);
  const { cartProducts } = useSelector((state) => state.cart);
  const [text] = useTranslation("global");

  const items = [
    {
      label: (
        <div onClick={() => setEditModal(!editModal)} className="flex">
          <FaPencilAlt /> {text("productCard.edit")}
        </div>
      ),
      key: "0",
    },
    {
      label: (
        <Popconfirm
          title="Вы хотите удалить?"
          onConfirm={() => dispatch(removeProduct(item.id))}
        >
          <div className="flex">
            <BiSolidTrash /> {text("productCard.delete")}
          </div>
        </Popconfirm>
      ),
      key: "1",
    },
    {
      label: (
        <div
          onClick={() =>
            dispatch(updateProduct({ ...item, status: !item.status }))
          }
          className="flex"
        >
          {item.status ? (
            <>
              <RiEyeCloseFill /> {text("productCard.hide")}
            </>
          ) : (
            <>
              <RiEyeFill /> {text("productCard.show")}
            </>
          )}
        </div>
      ),
      key: "2",
    },
  ];

  function check() {
    if (cartProducts.some((e) => e.id === item.id)) {
      dispatch(removeCartProduct(item));
    } else {
      dispatch(addCartProduct({ ...item, count: 1 }));
    }
  }

  return (
    <Style>
      <div className="title">
        <div>
          <h3>{item.name}</h3>
        </div>
        {SandB(
          <Dropdown menu={{ items }} trigger={["click"]}>
            <Button className="dots">
              <HiDotsHorizontal />
            </Button>
          </Dropdown>,
          ""
        )}
      </div>
      <Carousel arrows autoplay>
        {item.images.map((img, index) => (
          <div key={index}>
            <div className="image">
              <Image src={baseUrl(`/images/${img}`)} className="img" />
            </div>
          </div>
        ))}
      </Carousel>
      <div className="content">
        <div className="bodyText">
          <b>
            {formatNumber(item.price)} {item.current}
          </b>
          <br />
          <b>
            {item.amount} {text("productCard.x")}
          </b>
          <Tooltip title={item.description}>
            <p className="text">{item.description}</p>
          </Tooltip>
          {SandB(
            <p>
              {item.status
                ? text("productCard.active")
                : text("productCard.disactive")}
            </p>,
            ""
          )}
        </div>
        {SandB(
          "",
          <Button
            className="dots"
            onClick={check}
            disabled={
              cartProducts?.[0]
                ? cartProducts?.[0]?.author !== item.author
                : false
            }
          >
            {cartProducts.some((e) => e.id === item.id) ? (
              <MdRemoveShoppingCart />
            ) : (
              <MdOutlineShoppingCart />
            )}
          </Button>
        )}
      </div>
      <ProductEditor open={editModal} setOpen={setEditModal} data={item} />
    </Style>
  );
}
