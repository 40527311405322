import styled from "styled-components";

export const Block = styled.div`
  width: 100%;
  min-height: 100dvh;
  .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
    gap: 20px;
    padding: 20px;
  }
  @media screen and (max-width: 600px) {
    .cards {
      width: 100%;
      padding: 10px;
      gap: 10px;
    }
  }
`;
