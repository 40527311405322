import styled from "styled-components";

export const Block = styled.div`
  width: 100%;
  min-height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 600px) {
  }
`;
