import React from "react";
import { Block } from "../styles/auth";
import { Button, Form, Input } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { reset, toggleLoader } from "../redux/reducers/auth";
import { useTranslation } from "react-i18next";
import Anchor from "../components/anchor";
import LogoCard from "../components/logoCard";

export default function Reset() {
  const dispatch = useDispatch();
  const { resetLoader } = useSelector((state) => state.auth);
  const [text] = useTranslation("global");

  function submit(data) {
    dispatch(toggleLoader("resetLoader"));
    dispatch(reset({ ...data, email: localStorage.getItem("userEmail") }));
  }

  return (
    <Block>
      <LogoCard />
      <Form name="reset" layout="vertical" className="form" onFinish={submit}>
        <Form.Item
          name="otp"
          label={text("reset.otp")}
          rules={[{ required: true, message: text("reset.otpMessage") }]}
        >
          <Input.OTP className="otp" maxLength={6} />
        </Form.Item>
        <Form.Item
          name="newPassword"
          label={text("reset.newPassword")}
          rules={[
            { required: true, message: text("reset.newPasswordMessage") },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            type="password"
            placeholder={text("reset.newPassword")}
            className="input"
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={resetLoader}
            loading={resetLoader}
          >
            {text("reset.resetButton")}
          </Button>
        </Form.Item>
        <Anchor
          text={text("reset.rememberedPasswordText")}
          route="/login"
          routeName={text("reset.backRouteName")}
        />
      </Form>
    </Block>
  );
}
