import styled from "styled-components";

export const Style = styled.div`
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    .header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      input {
        width: 200px;
        height: 40px;
      }
    }
    .cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: baseline;
      gap: 20px;
      word-wrap: break-word;
    }
  }
  @media screen and (max-width: 600px) {
    .container {
      padding: 10px;
      .cards {
        gap: 10px;
      }
      .header {
        flex-direction: column;
        align-items: start;
        gap: 10px;
        input {
          width: 100%;
        }
      }
    }
  }
`;
