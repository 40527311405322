import React, { useEffect, useState } from "react";
import { Block } from "./style";
import { Button } from "antd";
import { TiMinus } from "react-icons/ti";
import { FaPlus } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import { removeCartProduct, setCartProduct } from "../../redux/reducers/cart";

export default function Counter({ item }) {
  const dispatch = useDispatch();
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    if (item.count < 1) {
      dispatch(removeCartProduct(item));
    }
    if (item.amount === item.count) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [item.count]);

  return (
    <Block>
      <Button
        onClick={() =>
          dispatch(setCartProduct({ ...item, count: item.count - 1 }))
        }
      >
        <TiMinus />
      </Button>
      <b>{item?.count}</b>
      <Button
        disabled={disable}
        onClick={() =>
          dispatch(setCartProduct({ ...item, count: item.count + 1 }))
        }
      >
        <FaPlus />
      </Button>
    </Block>
  );
}
