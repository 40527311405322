import { Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { lang, langOptions } from "../../data/data";

export default function LangSelector() {
  const [_, i18n] = useTranslation("global");

  function selectLanguage(event) {
    i18n.changeLanguage(event);
    localStorage.setItem("lang", event);
  }

  return (
    <Select
      defaultValue={lang}
      options={langOptions}
      onChange={selectLanguage}
    />
  );
}
