import styled from "styled-components";

export const Style = styled.div`
  width: 300px;
  border-radius: 20px;
  padding: 20px;
  border: 1px solid #fff;
  background-color: #ffffffae;
  backdrop-filter: blur(20px);
  .image {
    background-color: #e4946fac;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;
    img {
      border-radius: 10px;
      height: 150px;
      object-fit: cover;
    }
  }
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    h3 {
      margin: 0;
    }
    b {
      font-size: 13px;
    }
  }
  .dots {
    height: 40px;
    width: 40px;
    color: #fff;
    border-radius: 50%;
    background-color: #000000;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    &:hover {
      background-color: #12131d !important;
    }
    &:disabled {
      background-color: #52525270;
      color: #fff;
    }
  }
  h3 {
    margin-top: 10px;
  }
  .content {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: end;
    .bodyText {
      width: calc(100% - 50px);
      .text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      p {
        white-space: nowrap;
      }
    }
  }
  @media screen and (max-width: 600px) {
    width: calc(50% - 5px);
    padding: 10px;
    .image {
      height: 100px;
    }
  }
`;
