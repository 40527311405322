import React, { useEffect, useState } from "react";
import { Button, Drawer, Form, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { FormStyle } from "../styles/content";
import { editUser, toggleLoader } from "../redux/reducers/users";
import { SandB } from "../utils";
import Uploader from "../components/uploader";
import { useTranslation } from "react-i18next";

export default function UserEditor({ open, setOpen }) {
  const [text] = useTranslation("global");
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { userLoader, user } = useSelector((state) => state.users);
  const [image, setImage] = useState("");
  const { TextArea } = Input;

  useEffect(() => {
    if (user.image) {
      setImage(user.image);
    }
  }, [user.image]);

  useEffect(() => {
    if (!userLoader) {
      setOpen(false);
      form.resetFields();
    }
  }, [userLoader]);

  function onFinish(data) {
    dispatch(toggleLoader("userLoader"));
    dispatch(editUser({ ...user, ...data, image }));
  }

  const onClose = () => setOpen(false);

  return (
    <Drawer title={text("userEditor.title")} onClose={onClose} open={open}>
      <Uploader image={image} setImage={setImage} />
      <FormStyle>
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          initialValues={user}
        >
          {SandB(
            <Form.Item
              name={"company"}
              label={text("userEditor.company")}
              rules={[
                {
                  required: true,
                  message: text("userEditor.companyPlaceholder"),
                },
              ]}
            >
              <Input className="input" />
            </Form.Item>,
            ""
          )}
          <Form.Item
            name={"username"}
            label={text("userEditor.username")}
            rules={[
              {
                required: true,
                message: text("userEditor.usernamePlaceholder"),
              },
            ]}
          >
            <Input className="input" />
          </Form.Item>
          <Form.Item
            name={"lastname"}
            label={text("userEditor.lastname")}
            rules={[
              {
                required: true,
                message: text("userEditor.lastnamePlaceholder"),
              },
            ]}
          >
            <Input className="input" />
          </Form.Item>
          <Form.Item
            name={"phone"}
            label={text("userEditor.phone")}
            rules={[
              { required: true, message: text("userEditor.phonePlaceholder") },
            ]}
          >
            <Input className="input" />
          </Form.Item>
          <Form.Item
            name="type"
            label={text("userEditor.userType")}
            rules={[
              {
                required: true,
                message: text("userEditor.userTypePlaceholder"),
              },
            ]}
          >
            <Select
              placeholder={text("userEditor.userTypePlaceholder")}
              className="input"
            >
              <Select.Option value="buyer">
                {text("userEditor.buyer")}
              </Select.Option>
              <Select.Option value="seller">
                {text("userEditor.seller")}
              </Select.Option>
            </Select>
          </Form.Item>
          {SandB(
            <Form.Item
              name="activityType"
              label={text("userEditor.activityType")}
              rules={[
                {
                  required: true,
                  message: text("userEditor.activityTypePlaceholder"),
                },
              ]}
            >
              <Select
                placeholder={text("userEditor.activityTypePlaceholder")}
                className="input"
              >
                <Select.Option value="manufacturer">
                  {text("userEditor.manufacturer")}
                </Select.Option>
                <Select.Option value="distributor">
                  {text("userEditor.distributor")}
                </Select.Option>
                <Select.Option value="wholesale">
                  {text("userEditor.wholesale")}
                </Select.Option>
                <Select.Option value="retail">
                  {text("userEditor.retail")}
                </Select.Option>
                <Select.Option value="services">
                  {text("userEditor.services")}
                </Select.Option>
              </Select>
            </Form.Item>,
            ""
          )}
          <Form.Item
            name="address"
            label={text("register.address")}
            rules={[
              { required: true, message: text("register.addressMessage") },
            ]}
          >
            <Input placeholder={text("register.address")} className="input" />
          </Form.Item>
          <Form.Item
            name="productType"
            label={text("userEditor.productType")}
            rules={[
              { required: true, message: text("userEditor.productTypeMsg") },
            ]}
          >
            <Input
              placeholder={text("userEditor.productType")}
              className="input"
            />
          </Form.Item>
          <Form.Item
            name="country"
            label={text("userEditor.country")}
            rules={[
              {
                required: true,
                message: text("userEditor.countryPlaceholder"),
              },
            ]}
          >
            <Select
              placeholder={text("userEditor.countryPlaceholder")}
              showSearch
              className="input"
            >
              {require("../data/countries.json").map((item, index) => (
                <Select.Option value={item.country} key={index}>
                  {item.country}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="bio"
            label={text("userEditor.bio")}
            rules={[
              {
                required: true,
                message: text("userEditor.bioMsg"),
              },
            ]}
          >
            <TextArea
              showCount
              maxLength={200}
              placeholder={text("userEditor.bio")}
            />
          </Form.Item>
          <Form.Item>
            <Button
              htmlType="submit"
              loading={userLoader}
              className="text-black mt-5 btn"
            >
              {text("userEditor.submit")}
            </Button>
          </Form.Item>
        </Form>
      </FormStyle>
    </Drawer>
  );
}
