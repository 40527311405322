import React from "react";
import { Style } from "./style";
import { Image } from "antd";
import { baseUrl } from "../../utils";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function UserCard({ item }) {
  const { products } = useSelector((state) => state.products);
  const navigate = useNavigate();
  const [text] = useTranslation("global");

  function activity(value) {
    switch (value) {
      case "manufacturer":
        return text("userEditor.manufacturer");
      case "distributor":
        return text("userEditor.distributor");
      case "wholesale":
        return text("userEditor.wholesale");
      case "retail":
        return text("userEditor.retail");
      case "services":
        return text("userEditor.services");
      default:
        break;
    }
  }

  return (
    <Style onClick={() => navigate(`/seller/${item.id}`)}>
      <div className="head">
        <div className="image">
          {item.image ? (
            <Image src={baseUrl(`/images/${item.image}`)} />
          ) : (
            <b>{item.username[0]}</b>
          )}
        </div>
        <div>
          <h4>{item.username}</h4>
          <p>{item.company}</p>
        </div>
      </div>
      <div className="content">
        <p>{activity(item.activityType)}</p>
        <p>
          {text("userCard.amount")}{" "}
          {products
            .filter((e) => e.status)
            .reduce((a, v) => (v.author === item.id ? (a += 1) : a), 0)}
        </p>
        <p>{item.productType}</p>
        <a target="_blank" href={`https://mailto:${item.email}`}>
          {item.email}
        </a>
        <p>{item.phone}</p>
        <p>{item.address}</p>
        <p>{item.bio}</p>
      </div>
    </Style>
  );
}
