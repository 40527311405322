import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "./redux/reducers/users";
import { useLocation, useNavigate } from "react-router-dom";
import { getProducts } from "./redux/reducers/products";
import { getCategories } from "./redux/reducers/data";
import Router from "./Router";

export default function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { user } = useSelector((state) => state.users);

  useEffect(() => {
    const id = localStorage.getItem("userId");
    if (id || pathname === "/reset") {
      dispatch(getUser(id, "getUser"));
      dispatch(getProducts());
      dispatch(getCategories());
    } else navigate("/login");
  }, []);

  useEffect(() => {
    if (user.ban) navigate("/ban");
    if (user == {}) {
      localStorage.clear();
      navigate("/login");
    }
  }, [user]);

  return <Router />;
}
