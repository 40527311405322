import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import store from "./redux/store";
import i18next from "i18next";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import { lang } from "./data/data";
import "./index.css";

i18next.init({
  interpolation: { escapeValue: false },
  lng: lang,
  resources: {
    uz: { global: require("./data/languages/uzb.json") },
    en: { global: require("./data/languages/eng.json") },
    ru: { global: require("./data/languages/rus.json") },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <I18nextProvider i18n={i18next}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </I18nextProvider>
);
