import styled from "styled-components";

export const Style = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .inputs {
      display: flex;
      gap: 10px;
      input,
      .select {
        width: 200px;
        height: 40px;
      }
      button {
        background-color: #000000;
        color: #fff;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          background-color: #12131d !important;
        }
        &:disabled {
          color: #fff;
        }
      }
    }
  }
  .cards {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
  }
  @media screen and (max-width: 600px) {
    .cards {
      gap: 10px;
    }
    .header {
      flex-direction: column;
      align-items: start;
      gap: 10px;
      .inputs {
        flex-wrap: wrap;
        width: 100%;
        input,
        .select {
          width: 100%;
        }
      }
    }
  }
`;
