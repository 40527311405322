import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { apiCall } from "../api-call";
import { TR } from "../../data/lang";

const products = createSlice({
  name: "products",
  initialState: {
    products: [],
    userProducts: [],
    creatorLoader: false,
    editorLoader: false,
  },
  reducers: {
    getProducts: (state, { payload }) => {
      state.products = payload;
    },
    setUserProducts: (state, { payload }) => {
      let userProducts = [];
      state.products.map((event) => {
        if (event.author === payload) {
          userProducts.push(event);
        }
      });
      state.userProducts = userProducts;
    },
    updateProductAction: (state, { payload }) => {
      state.products.map((item, index) => {
        if (item.id === payload.id) {
          state.products.splice(index, 1, payload);
        }
      });
      state.userProducts.map((item, index) => {
        if (item.id === payload.id) {
          state.userProducts.splice(index, 1, payload);
        }
      });
      state.editorLoader = false;
    },
    removeProduct: (state, { payload }) => {
      state.products.map((item, index) => {
        if (item.id === payload.id) {
          state.products.splice(index, 1);
        }
      });
      state.userProducts.map((item, index) => {
        if (item.id === payload.id) {
          state.userProducts.splice(index, 1);
        }
      });
      state.editorLoader = false;
      message.success(TR("products.remove"));
    },
    createProduct: (state, { payload }) => {
      state.products.push(payload);
      state.userProducts.push(payload);
      state.creatorLoader = false;
      message.success(TR("products.create"));
    },
    toggleLoader: (state, { payload }) => {
      state[payload] = !state[payload];
    },
    error: (state, { payload }) => {
      message.error(payload);
      state.creatorLoader = false;
      state.editorLoader = false;
    },
  },
});

const keys = { onFail: "products/error", url: "/products" };

export const getProducts = (data) => {
  return apiCall({
    ...keys,
    method: "get",
    onSuccess: "products/getProducts",
    data,
  });
};

export const createProduct = (data) => {
  return apiCall({
    ...keys,
    method: "post",
    onSuccess: "products/createProduct",
    data,
  });
};

export const updateProduct = (data) => {
  return apiCall({
    ...keys,
    url: `/products/${data.id}`,
    method: "put",
    onSuccess: "products/updateProductAction",
    data,
  });
};

export const removeProduct = (id) => {
  return apiCall({
    ...keys,
    url: `/products/${id}`,
    method: "delete",
    onSuccess: "products/removeProduct",
  });
};

export const { toggleLoader, setUserProducts, updateProductAction } =
  products.actions;
export default products.reducer;
