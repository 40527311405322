import styled from "styled-components";

export const Block = styled.div`
  .box {
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding: 20px;
    gap: 20px;
    .user {
      width: 300px;
      border-radius: 20px;
      padding: 20px;
      border: 1px solid #fff;
      background-color: #ffffffae;
      backdrop-filter: blur(20px);
      .image {
        width: 250px;
        height: 250px;
        border-radius: 50%;
        border: 1px solid #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        margin: auto;
        img {
          object-fit: cover;
          min-width: 250px;
          min-height: 250px;
        }
        b {
          font-size: 200px;
          user-select: none;
        }
      }
      h1 {
        margin-block: 10px;
      }
      a {
        text-decoration: none;
        color: #44599c;
      }
    }
  }
  @media screen and (max-width: 700px) {
    .box {
      flex-direction: column;
      width: 100%;
      padding: 10px;
      .user {
        width: 100%;
      }
    }
  }
`;
