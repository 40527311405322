import React from "react";
import { Block } from "./style";
import { Image } from "antd";
import { useSelector } from "react-redux";
import { baseUrl } from "../../utils";
import { SandB } from "../../utils";
import Navbar from "../../components/navbar";
import SellerProducts from "../../components/sellerProducts";
import Loader from "../../components/loader";

export default function Profile() {
  const { user } = useSelector((state) => state.users);

  return (
    <Block>
      <Navbar />
      {user.username ? (
        <div className="box">
          <div className="user">
            <div className="image">
              {user.image ? (
                <Image src={baseUrl(`/images/${user.image}`)} />
              ) : (
                <b>{user?.username?.[0]}</b>
              )}
            </div>
            <h1>{user.username}</h1>
            <p>{user?.lastname}</p>
            <a target="_blank" href={`https://mailto:${user.email}`}>
              {user.email}
            </a>
            <p>{user?.phone}</p>
          </div>
          {SandB(<SellerProducts id={localStorage.getItem("userId")} />, "")}
        </div>
      ) : (
        <Loader w="100%" h="100dvh" />
      )}
    </Block>
  );
}
