import styled from "styled-components";

export const Block = styled.div`
  width: 100%;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  .card {
    width: 380px;
    border-radius: 20px;
    padding: 10px;
    border: 1px solid #fff;
    background-color: #ffffffae;
    backdrop-filter: blur(20px);
    justify-content: space-between;
    .logo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 5px;
      text-decoration: none;
      color: #000;
      img {
        width: 50px;
        border-radius: 10px;
      }
      h2 {
        font-weight: 600;
      }
    }
    .ant-select {
      height: 40px;
    }
    .ant-select-selector {
      border-radius: 13px;
    }
  }
  .form {
    width: 380px;
    border-radius: 20px;
    padding: 20px;
    border: 1px solid #fff;
    background-color: #ffffffae;
    backdrop-filter: blur(20px);
    .input {
      height: 40px;
      display: flex;
      align-items: center;
      gap: 5px;
    }
    button {
      width: 100%;
      height: 45px;
      font-size: 20px;
      margin-top: 10px;
      background-color: #000000;
      &:hover {
        background-color: #303030 !important;
      }
      &:disabled {
        color: #fff;
      }
    }
  }
  @media screen and (max-width: 600px) {
    padding: 20px;
  }
`;
