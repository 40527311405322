import React, { useState } from "react";
import { baseUrl, headers } from "../../utils";
import { Block } from "./style";
import { useTranslation } from "react-i18next";
import Loader from "../loader";
import axios from "axios";

export default function Uploader({ image, setImage }) {
  const [value, setValue] = useState("");
  const [loader, setLoader] = useState(false);
  const [text] = useTranslation("global");

  const upload = (e) => {
    setLoader(true);
    const data = new FormData();
    data.append("image", e);
    axios
      .post(baseUrl("/images"), data, headers)
      .then((res) => {
        setImage(res.data.id);
      })
      .finally(() => {
        setLoader(false);
        setValue("");
      });
  };

  return (
    <Block htmlFor="image">
      {loader ? (
        <Loader />
      ) : image !== "" && image !== null ? (
        <img src={baseUrl(`/images/${image}`)} className="img" />
      ) : (
        <>
          <img
            src={require("../../assets/upload.png")}
            className="placeholder"
          />
          <p>{text("uploader.upload")}</p>
        </>
      )}
      <input
        type="file"
        id="image"
        name="image"
        value={value}
        accept="image/*"
        onChange={(e) => upload(e.target.files[0])}
      />
    </Block>
  );
}
