import React, { useEffect } from "react";
import { Block } from "./style";
import { Image } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { baseUrl } from "../../utils";
import { useParams } from "react-router-dom";
import { getUser } from "../../redux/reducers/users";
import Navbar from "../../components/navbar";
import SellerProducts from "../../components/sellerProducts";
import Loader from "../../components/loader";

export default function Seller() {
  const { seller } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getUser(id, "getSeller"));
  }, [id]);

  return (
    <Block>
      <Navbar />
      {seller.id ? (
        <div className="box">
          <div className="user">
            <div className="image">
              {seller.image ? (
                <Image src={baseUrl(`/images/${seller.image}`)} />
              ) : (
                <b>{seller.username?.[0]}</b>
              )}
            </div>
            <h1>{seller.username}</h1>
            <p>{seller?.lastname}</p>
            <a target="_blank" href={`https://mailto:${seller.email}`}>
              {seller.email}
            </a>
          </div>
          <SellerProducts id={seller.id} />
        </div>
      ) : (
        <Loader w="100%" h="100dvh" />
      )}
    </Block>
  );
}
