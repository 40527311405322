import React, { useEffect, useState } from "react";
import { Style } from "./style";
import { SandB, baseUrl, formatNumber, headers } from "../../utils";
import { Button, Divider, Image } from "antd";
import { FaCheck } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import { editOrder } from "../../redux/reducers/orders";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";
import countries from "../../data/countries.json";

export default function OrderCard({ item }) {
  const [user, setUser] = useState({ seller: {}, buyer: {} });
  const dispatch = useDispatch();
  const [text] = useTranslation("global");
  const users = useSelector((state) => state.users);
  const currency = countries.find(
    (e) => e.country === users.user.country
  )?.currency;

  useEffect(() => {
    Object.keys(user).forEach((key) => {
      axios.get(baseUrl(`/users/${item[key]}`), headers).then((user) => {
        setUser((p) => ({ ...p, [key]: user.data }));
      });
    });
  }, []);

  const total = item.products.reduce(
    (a, b) => (a += b.current === currency ? b.price * b.count : 0),
    0
  );

  const totalUSD = item.products.reduce(
    (a, b) => (a += b.current === "$" ? b.price * b.count : 0),
    0
  );

  function ready() {
    dispatch(editOrder(item));
  }

  return !user.seller.ban &&
    !user.buyer.ban &&
    user.seller.id &&
    user.buyer.id ? (
    <Style>
      <div className="head">
        <div className="user">
          <div className="image">
            {item.image ? (
              <img
                src={baseUrl(`/images/${user[SandB("buyer", "seller")].image}`)}
              />
            ) : (
              <b>{user[SandB("buyer", "seller")].username[0]}</b>
            )}
          </div>
          <div className="text">
            <h4>{user[SandB("buyer", "seller")].username}</h4>
            <p>{user[SandB("buyer", "seller")].company}</p>
          </div>
        </div>
        {SandB(
          <Button
            onClick={ready}
            disabled={item.ready}
            icon={item.ready ? <FaCheck /> : <RxCross2 />}
          >
            {text("orderCard.ready")}
          </Button>,
          <b className={item.ready ? "ready" : "notReady"}>
            {item.ready ? text("orderCard.ready") : text("orderCard.notReady")}
          </b>
        )}
      </div>
      <Divider>{text("orderCard.products")}</Divider>
      <div className="products">
        {item.products.map((e, i) => (
          <div className="product" key={i}>
            <Image src={baseUrl(`/images/${e.images[0]}`)} className="image" />
            <div className="text">
              <h3>{e.name}</h3>
              <div>
                <b>
                  {text("orderCard.amount")} {e.count}
                  {text("orderCard.x")}
                </b>
                <b>
                  {formatNumber(e.price * e.count)} {e.current}
                </b>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Divider>{text("orderCard.info")}</Divider>
      <h5>
        {text("orderCard.total")}{" "}
        <b className="price">
          {formatNumber(total)} {currency}
        </b>{" "}
        & <b className="price">{formatNumber(totalUSD)} $</b>
      </h5>
      <h5>
        {text("orderCard.address")} {item.address}
      </h5>
    </Style>
  ) : (
    ""
  );
}
