import React from "react";
import { Route, Routes } from "react-router-dom";
import Register from "./pages/register";
import Login from "./pages/login";
import Forgot from "./pages/forgot";
import Reset from "./pages/reset";
import Home from "./pages/home";
import Profile from "./pages/profile";
import Products from "./pages/products";
import Sellers from "./pages/sellers";
import Seller from "./pages/seller";
import Ban from "./pages/ban";

export default function Router() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/register" element={<Register />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgot" element={<Forgot />} />
      <Route path="/reset" element={<Reset />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/products" element={<Products />} />
      <Route path="/sellers" element={<Sellers />} />
      <Route path="/seller/:id" element={<Seller />} />
      <Route path="/ban" element={<Ban />} />
    </Routes>
  );
}
