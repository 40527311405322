import { lang } from "./data";

const langs = {
  ru: {
    auth: {
      authed: "Авторизован",
      login: "Вошел в систему",
    },
    products: {
      remove: "Продукт удален",
      create: "Продукт создан",
    },
    users: {
      edit: "Данные отредактированы",
    },
  },
  en: {
    auth: {
      authed: "Authorized",
      login: "Loged In",
    },
    products: {
      remove: "Product deleted",
      create: "Product created",
    },
    users: {
      edit: "Data edited",
    },
  },
  uz: {
    auth: {
      authed: "Avtorizatsiyadan o'tildi",
      login: "Tizimga kirildi",
    },
    products: {
      remove: "Mahsulot o'chirildi",
      create: "Mahsulot yaratildi",
    },
    users: {
      edit: "Ma'lumotlar tahrirlandi",
    },
  },
};

export function TR(path) {
  const lines = path.split(".");
  return langs[lang][lines[0]][lines[1]];
}
