import React, { useState } from "react";
import { Style } from "./style";
import { Button, Dropdown, Popconfirm } from "antd";
import { IoAddCircle } from "react-icons/io5";
import { FaOpencart } from "react-icons/fa";
import { TiUser } from "react-icons/ti";
import { Link, useNavigate } from "react-router-dom";
import { SandB, baseUrl } from "../../utils";
import { GoX } from "react-icons/go";
import { CgMenuRight } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { BiLogOut } from "react-icons/bi";
import { logOut } from "../../redux/reducers/users";
import { CiSettings } from "react-icons/ci";
import { useTranslation } from "react-i18next";
import ProductCreator from "../../content/productCreator";
import Cart from "../../content/cart";
import UserEditor from "../../content/userEditor";
import LangSelector from "../langSelector";

export default function Navbar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [menuOpen, setMenuOpen] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [cartModal, setCartModal] = useState(false);
  const { user } = useSelector((state) => state.users);
  const [userEditor, setUserEditor] = useState(false);
  const [text] = useTranslation("global");

  const items = [
    {
      label: (
        <div className="flex" onClick={() => navigate("/profile")}>
          <TiUser /> {text("navbar.profile")}
        </div>
      ),
      key: "0",
    },
    {
      label: (
        <div onClick={toggleUserEditor} className="flex">
          <CiSettings /> {text("navbar.settings")}
        </div>
      ),
      key: "1",
    },
    {
      label: (
        <Popconfirm
          onConfirm={logout}
          title={text("navbar.exitText")}
          okText={text("navbar.ok")}
          cancelText={text("navbar.cancel")}
          className="flex"
        >
          <BiLogOut /> {text("navbar.exit")}
        </Popconfirm>
      ),
      key: "2",
    },
  ];

  function toggleUserEditor() {
    setUserEditor(!userEditor);
  }

  function logout() {
    localStorage.clear();
    dispatch(logOut());
    navigate("/login");
  }

  return (
    <Style open={menuOpen}>
      <div className="brand">
        <Link className="logo" to="/">
          <img src={require("../../assets/logo.png")} />
          <h2>Uninroam</h2>
        </Link>
        <Button className="menu-btn" onClick={() => setMenuOpen(!menuOpen)}>
          {menuOpen ? <GoX /> : <CgMenuRight />}
        </Button>
      </div>
      <div className="line">
        {SandB(
          "",
          <div>
            <Link to="/sellers">{text("navbar.sellers")}</Link>
            <Link to="/products">{text("navbar.products")}</Link>
          </div>
        )}
        <LangSelector />
        <div>
          {SandB(
            <Button
              icon={<IoAddCircle />}
              onClick={() => setCreateModal(!createModal)}
            >
              {text("navbar.create")}
            </Button>,
            <Button
              icon={<FaOpencart />}
              onClick={() => setCartModal(!cartModal)}
            >
              {text("navbar.cart")}
            </Button>
          )}
          <Dropdown menu={{ items }} trigger={["click"]}>
            <Button className="avatar">
              {user.image ? (
                <img src={baseUrl(`/images/${user.image}`)} />
              ) : (
                <b>{user.username?.[0]}</b>
              )}
            </Button>
          </Dropdown>
        </div>
      </div>
      <ProductCreator open={createModal} setOpen={setCreateModal} />
      <Cart open={cartModal} setOpen={setCartModal} />
      <UserEditor open={userEditor} setOpen={setUserEditor} />
    </Style>
  );
}
