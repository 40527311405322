import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { apiCall } from "../api-call";

const orders = createSlice({
  name: "orders",
  initialState: {
    orders: [],
    readyLoader: false,
    buyLoader: false,
    dataLoader: false,
  },
  reducers: {
    getOrders: (state, { payload }) => {
      state.orders = payload;
      state.dataLoader = true;
    },
    addOrder: (state, { payload }) => {
      const item = state.orders.find((item) => item.id === payload.id);
      if (!item) {
        state.orders.push(payload);
      }
      state.buyLoader = "end";
    },
    updateOrder: (state, { payload }) => {
      state.orders.map((item, index) => {
        if (item.id === payload.id) {
          state.orders.splice(index, 1, payload);
        }
      });
    },
    toggleLoader: (state, { payload }) => {
      state[payload] = !state[payload];
    },
    setLoader: (state, { payload }) => {
      state[payload.loader] = payload.value;
    },
    error: (state, { payload }) => {
      message.error(payload);
      state.readyLoader = false;
    },
  },
});

const keys = { url: "/orders", onFail: "orders/error" };

export const getOrders = () => {
  return apiCall({ ...keys, method: "get", onSuccess: "orders/getOrders" });
};

export const createOrder = (data) => {
  return apiCall({
    ...keys,
    method: "post",
    onSuccess: "orders/addOrder",
    data,
  });
};

export const editOrder = (data) => {
  return apiCall({
    ...keys,
    url: "/orderReady",
    method: "put",
    onSuccess: "orders/updateOrder",
    data,
  });
};

export const { toggleLoader, addOrder, updateOrder, setLoader } =
  orders.actions;
export default orders.reducer;
