import React, { useEffect, useState } from "react";
import { Style } from "./style";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "antd";
import { getUsers } from "../../redux/reducers/users";
import { useTranslation } from "react-i18next";
import Navbar from "../../components/navbar";
import UserCard from "../../components/userCard";
import Loader from "../../components/loader";

export default function Sellers() {
  const { sellers } = useSelector((state) => state.users);
  const [search, setSearch] = useState("");
  const { user } = useSelector((state) => state.users);
  const [text] = useTranslation("global");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  return (
    <Style>
      <Navbar />
      {sellers.length > 0 ? (
        <div className="container">
          <div className="header">
            <h1 className="title">{text("sellers.title")}</h1>
            <Input
              placeholder={text("sellers.search")}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="cards">
            {sellers
              .filter((e) => e.country === user.country)
              .filter((e) =>
                e.username.toLowerCase().includes(search.toLowerCase())
              )
              .map((item, index) => (
                <UserCard item={item} key={index} />
              ))}
          </div>
        </div>
      ) : (
        <Loader w="100%" h="100dvh" />
      )}
    </Style>
  );
}
