import React from "react";
import LangSelector from "../langSelector";

export default function LogoCard() {
  return (
    <div className="card flex">
      <div className="logo">
        <img src={require("../../assets/logo.png")} />
        <h2>Uninroam</h2>
      </div>
      <LangSelector />
    </div>
  );
}
