import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { apiCall } from "../api-call";
import { TR } from "../../data/lang";

const auth = createSlice({
  name: "auth",
  initialState: {
    registerLoader: false,
    loginLoader: false,
    forgotLoader: false,
    resetLoader: false,
  },
  reducers: {
    register: (state, { payload }) => {
      localStorage.setItem("userId", payload.id);
      localStorage.setItem("token", payload.token);
      state.registerLoader = false;
      message.success(TR("auth.authed")).then(() => {
        window.location.pathname = "/";
      });
    },
    login: (state, { payload }) => {
      localStorage.setItem("userId", payload.id);
      localStorage.setItem("token", payload.token);
      state.loginLoader = false;
      message.success(TR("auth.login")).then(() => {
        window.location.pathname = "/";
      });
    },
    forgot: (state, { payload }) => {
      state.forgotLoader = false;
      message.success(payload).then(() => {
        window.location.pathname = "/reset";
      });
    },
    reset: (state, { payload }) => {
      state.resetLoader = false;
      message.success(payload).then(() => {
        window.location.pathname = "/login";
      });
    },
    toggleLoader: (state, { payload }) => {
      state[payload] = !state[payload];
    },
    error: (state, { payload }) => {
      message.error(payload);
      state.registerLoader = false;
      state.loginLoader = false;
      state.forgotLoader = false;
      state.resetLoader = false;
    },
  },
});

const keys = (path) => ({ onFail: "auth/error", url: path, onSuccess: path });

export const register = (data) => {
  return apiCall({ ...keys("auth/register"), method: "post", data });
};

export const login = (data) => {
  return apiCall({ ...keys("auth/login"), method: "post", data });
};

export const forgot = (data) => {
  return apiCall({ ...keys("auth/forgot"), method: "post", data });
};

export const reset = (data) => {
  return apiCall({ ...keys("auth/reset"), method: "post", data });
};

export const { toggleLoader } = auth.actions;
export default auth.reducer;
