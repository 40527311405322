import React from "react";
import { Block } from "../styles/auth";
import { Button, Form, Input } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { forgot, toggleLoader } from "../redux/reducers/auth";
import { useTranslation } from "react-i18next";
import Anchor from "../components/anchor";
import LogoCard from "../components/logoCard";

export default function Forgot() {
  const dispatch = useDispatch();
  const { forgotLoader } = useSelector((state) => state.auth);
  const [text] = useTranslation("global");

  function submit(data) {
    localStorage.setItem("userEmail", data?.email);
    dispatch(toggleLoader("forgotLoader"));
    dispatch(forgot(data));
  }

  return (
    <Block>
      <LogoCard />
      <Form name="forgot" layout="vertical" className="form" onFinish={submit}>
        <Form.Item
          name="email"
          label={text("forgot.email")}
          rules={[{ required: true, message: text("forgot.emailMessage") }]}
        >
          <Input
            prefix={<UserOutlined />}
            placeholder={text("forgot.email")}
            className="input"
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={forgotLoader}
            loading={forgotLoader}
          >
            {text("forgot.getCodeButton")}
          </Button>
        </Form.Item>
        <Anchor
          text={text("forgot.rememberedPasswordText")}
          route="/login"
          routeName={text("forgot.backRouteName")}
        />
      </Form>
    </Block>
  );
}
