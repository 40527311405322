import styled from "styled-components";

export const Style = styled.div`
  width: 400px;
  border-radius: 20px;
  padding: 20px;
  border: 1px solid #fff;
  background-color: #ffffffae;
  backdrop-filter: blur(20px);
  .head {
    display: flex;
    justify-content: space-between;
    .user {
      display: flex;
      align-items: center;
      gap: 10px;
      .image {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          object-fit: cover;
          min-height: 40px;
          min-width: 40px;
        }
        b {
          font-size: 20px;
          user-select: none;
        }
      }
      .text {
        p {
          font-size: 13px;
        }
      }
    }
    .ready {
      color: green;
    }
    .notReady {
      color: red;
    }
    button {
      height: 40px;
      color: #fff;
      border-radius: 40px;
      background-color: #000000;
      display: flex;
      align-items: center;
      &:hover {
        background-color: #12131d !important;
      }
      &:disabled {
        background-color: #52525270;
        color: #fff;
      }
    }
  }
  .products {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .product {
      display: flex;
      gap: 10px;
      .image {
        height: 50px;
        width: 50px;
        object-fit: cover;
        border-radius: 10px;
      }
      .text {
        width: 100%;
        div {
          display: flex;
          justify-content: space-between;
          b {
            font-size: 13px;
          }
        }
      }
    }
  }
  h5 {
    color: #3f3f3f;
    font-weight: 400;
    .price {
      color: #000000;
      font-weight: 900;
    }
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    padding: 10px;
    .image {
      height: 100px;
    }
  }
`;
