import { configureStore } from "@reduxjs/toolkit";
import api from "./middleware/api";
import auth from "./reducers/auth";
import products from "./reducers/products";
import users from "./reducers/users";
import cart from "./reducers/cart";
import orders from "./reducers/orders";
import data from "./reducers/data";

const store = configureStore({
  reducer: { auth, products, users, cart, orders, data },
  middleware: () => [api],
});

export default store;
