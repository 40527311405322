import React, { useEffect, useState } from "react";
import {
  Button,
  Drawer,
  Form,
  Input,
  InputNumber,
  Select,
  message,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { FormStyle } from "../styles/content";
import { updateProduct, toggleLoader } from "../redux/reducers/products";
import MultiUploader from "../components/multiUploader";
import { useTranslation } from "react-i18next";
import countries from "../data/countries.json";

export default function ProductEditor({ open, setOpen, data }) {
  const [text] = useTranslation("global");
  const dispatch = useDispatch();
  const {
    products: { editorLoader },
    data: { categories },
    users: { user },
  } = useSelector((state) => state);
  const { TextArea } = Input;
  const [value, setValue] = useState({});
  const [images, setImages] = useState([]);
  const [current, setCurrent] = useState("");
  const currency = countries.find((e) => e.country === user.country)?.currency;

  useEffect(() => {
    setValue(data);
    setImages(data.images);
    setCurrent(data.current);
  }, [data]);

  useEffect(() => {
    if (!editorLoader) {
      setOpen(false);
    }
  }, [editorLoader]);

  const onFinish = (values) => {
    if (images.length > 0) {
      dispatch(toggleLoader("editorLoader"));
      dispatch(updateProduct({ ...data, ...values, images, current }));
    } else {
      message.warning(text("productEditor.uploadPhoto"));
    }
  };

  const selectAfter = (
    <Select value={current} onChange={(value) => setCurrent(value)}>
      <Select.Option value="$">$</Select.Option>
      <Select.Option value={currency}>{currency}</Select.Option>
    </Select>
  );

  return (
    <Drawer
      title={text("productEditor.title")}
      onClose={() => setOpen(false)}
      open={open}
    >
      <MultiUploader images={images} setImages={setImages} />
      <FormStyle>
        <Form onFinish={onFinish} layout="vertical" initialValues={value}>
          <Form.Item
            name={"name"}
            label={text("productEditor.name")}
            rules={[
              {
                required: true,
                message: text("productEditor.namePlaceholder"),
              },
            ]}
          >
            <Input className="input" />
          </Form.Item>
          <Form.Item
            name={"category"}
            label={text("productEditor.category")}
            rules={[
              {
                required: true,
                message: text("productEditor.categoryPlaceholder"),
              },
            ]}
          >
            <Select
              showSearch
              options={categories?.map((e) => ({
                value: e.type,
                label: e.type,
              }))}
              className="input"
            />
          </Form.Item>
          <Form.Item
            name={"price"}
            label={text("productEditor.price")}
            rules={[
              {
                required: true,
                message: text("productEditor.pricePlaceholder"),
              },
            ]}
          >
            <InputNumber
              addonAfter={selectAfter}
              className="number"
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            name={"amount"}
            label={text("productEditor.amount")}
            rules={[
              {
                required: true,
                message: text("productEditor.amountPlaceholder"),
              },
            ]}
          >
            <InputNumber className="number" min={0} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name={"description"}
            label={text("productEditor.description")}
            rules={[
              {
                required: true,
                message: text("productEditor.descriptionPlaceholder"),
              },
            ]}
          >
            <TextArea rows={4} className="textarea" />
          </Form.Item>
          <Form.Item>
            <Button
              htmlType="submit"
              loading={editorLoader}
              className="text-black mt-5 btn"
            >
              {text("productEditor.submit")}
            </Button>
          </Form.Item>
        </Form>
      </FormStyle>
    </Drawer>
  );
}
