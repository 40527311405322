import React from "react";
import { Block } from "../styles/auth";
import { Button, Form, Input } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { login, toggleLoader } from "../redux/reducers/auth";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Anchor from "../components/anchor";
import LangSelector from "../components/langSelector";

export default function Login() {
  const dispatch = useDispatch();
  const { loginLoader } = useSelector((state) => state.auth);
  const [text] = useTranslation("global");

  function submit(data) {
    dispatch(toggleLoader("loginLoader"));
    dispatch(login(data));
  }

  return (
    <Block>
      <div className="card flex">
        <div className="logo">
          <img src={require("../assets/logo.png")} />
          <h2>Uninroam</h2>
        </div>
        <LangSelector />
      </div>
      <Form name="login" layout="vertical" className="form" onFinish={submit}>
        <h2 className="title">{text("login.title")}</h2>
        <Form.Item
          name="email"
          label={text("login.email")}
          rules={[{ required: true, message: text("login.emailMessage") }]}
        >
          <Input
            prefix={<MailOutlined />}
            type="email"
            placeholder={text("login.email")}
            className="input"
          />
        </Form.Item>
        <Form.Item
          name="password"
          label={text("login.password")}
          rules={[{ required: true, message: text("login.passwordMessage") }]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            type="password"
            placeholder={text("login.password")}
            className="input"
          />
        </Form.Item>
        <Anchor
          text={text("login.forgotPasswordText")}
          route="/forgot"
          routeName={text("login.forgotPasswordRoute")}
        />
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={loginLoader}
            loading={loginLoader}
          >
            {text("login.loginButton")}
          </Button>
        </Form.Item>
        <Anchor
          text={text("login.createAccountText")}
          route="/register"
          routeName={text("login.createAccountRoute")}
        />
      </Form>
    </Block>
  );
}
