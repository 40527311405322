import React from "react";
import { Block } from "../styles/auth";
import { Button, Form, Input, Select } from "antd";
import { LockOutlined, MailOutlined, UserOutlined } from "@ant-design/icons";
import { register, toggleLoader } from "../redux/reducers/auth";
import { useDispatch, useSelector } from "react-redux";
import { GoLocation } from "react-icons/go";
import { useTranslation } from "react-i18next";
import Anchor from "../components/anchor";
import LangSelector from "../components/langSelector";
import LogoCard from "../components/logoCard";

export default function Register() {
  const dispatch = useDispatch();
  const { registerLoader } = useSelector((state) => state.auth);
  const [text] = useTranslation("global");

  function submit(data) {
    dispatch(toggleLoader("registerLoader"));
    dispatch(register(data));
  }

  return (
    <Block>
      <LogoCard />
      <Form
        name="register"
        layout="vertical"
        className="form"
        onFinish={submit}
      >
        <h2 className="title">{text("register.title")}</h2>
        <Form.Item
          name="username"
          label={text("register.username")}
          rules={[
            { required: true, message: text("register.usernameMessage") },
          ]}
        >
          <Input
            prefix={<UserOutlined />}
            placeholder={text("register.username")}
            className="input"
          />
        </Form.Item>
        <Form.Item
          name="email"
          label={text("register.email")}
          rules={[{ required: true, message: text("register.emailMessage") }]}
        >
          <Input
            prefix={<MailOutlined />}
            type="email"
            placeholder={text("register.email")}
            className="input"
          />
        </Form.Item>
        <Form.Item
          name="password"
          label={text("register.password")}
          rules={[
            { required: true, message: text("register.passwordMessage") },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            type="password"
            placeholder={text("register.password")}
            className="input"
          />
        </Form.Item>
        <Form.Item
          name="type"
          label={text("register.userType")}
          rules={[
            { required: true, message: text("register.userTypeMessage") },
          ]}
        >
          <Select placeholder={text("register.userType")} className="input">
            <Select.Option value="buyer">Покупатель</Select.Option>
            <Select.Option value="seller">Продавец</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="address"
          label={text("register.address")}
          rules={[{ required: true, message: text("register.addressMessage") }]}
        >
          <Input
            prefix={<GoLocation />}
            placeholder={text("register.address")}
            className="input"
          />
        </Form.Item>
        <Form.Item
          name="country"
          label={text("register.country")}
          rules={[{ required: true, message: text("register.countryMessage") }]}
        >
          <Select
            placeholder={text("register.country")}
            showSearch
            className="input"
          >
            {require("../data/countries.json").map((item, index) => (
              <Select.Option value={item.country} key={index}>
                {item.country}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={registerLoader}
            loading={registerLoader}
          >
            {text("register.registerButton")}
          </Button>
        </Form.Item>
        <Anchor
          text={text("register.loginText")}
          route="/login"
          routeName={text("register.loginRouteName")}
        />
      </Form>
    </Block>
  );
}
